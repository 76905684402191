import React from 'react'
import { useMediaQuery } from '@mui/material'
import '../styles/privacyPolicy.css'

export default function PrivacyPolicy() {
  const isMedia1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <div className={`w-full flex justify-center h-full py-10`}>
      <div
        className={`rounded-lg ${
          isMedia1025px ? 'w-full' : 'w-[50%]'
        }   color-border-default border shadow-lg overflow-auto styled-scrollbar p-8`}
      >
        <b>Global Privacy Policy - Fileverse</b>
        <div className="flex flex-col gap-4 w-fit text-justify">
          <ol className="counter flex flex-col gap-2">
            <li className="counter">
              <b>About this Privacy Policy</b>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  Fileverse Limited ( <strong>Fileverse, we, us</strong> or{' '}
                  <strong> our </strong>) has implemented this Privacy Policy to
                  provide information about what kinds of Personal Data we may
                  collect or hold, how we collect, hold, use and disclose that
                  Personal Data, choices you have regarding our use of that
                  Personal Data, and your ability to access or correct that
                  Personal Data. If you wish to make any inquiries regarding
                  this Privacy Policy, you should contact us in any of the ways
                  specified in paragraph 15.
                </li>
                <li className="counter">
                  From time to time, Fileverse may be related to other companies
                  and entities (related entities). This Privacy Policy applies
                  to the use of your Personal Data by us and by those related
                  entities. A reference in this Privacy Policy to Fileverse, we,
                  us or our is also a reference to those related entities.
                </li>
              </ol>
            </li>
            <li className="counter">
              <b>Personal Data</b>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  <strong>&quot;Personal Data&quot;</strong> is information or
                  an opinion, whether true or not, and whether recorded in a
                  material form or not, about an identified individual or an
                  individual who is reasonably identifiable from the data or
                  from the data and other information to which the organization
                  has or is likely to have access
                </li>
              </ol>
            </li>
            <li className="counter">
              <b>User Content and our services</b>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  Our services offer a straightforward and personalised solution
                  for storing your files, documents, photos, comments, messages,
                  and anything else you upload or share via our services (
                  <strong>User Content</strong>). Unless otherwise specified in
                  this privacy policy, Personal Data User Content. Our services
                  enable you to collaborate with others and work seamlessly
                  across various devices and platforms.
                </li>
                <li className="counter">
                  Our services offer two options, users can:
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>
                      create an account with us to access the full range of
                      services, or
                    </li>
                    <li>
                      use our services without an account, which will grant you
                      access to our services in a limited way.
                    </li>
                  </ol>
                </li>
                <li className="counter">
                  We use Privy.io to help facilitate account creation. Privy.io
                  is a platform that provides tools for secure identity
                  management and account verification.
                </li>
                <li className="counter">
                  Upon creation of an account via Privy.io, We will seek that
                  you connect your crypto wallet or social media account to
                  streamline account setup and for verification purposes. In the
                  event that you lose access to an account or otherwise request
                  Information about an account, we may request verification we
                  deem necessary before restoring access to or providing
                  information about such account.
                </li>
                <li className="counter">
                  For users with an account, to facilitate your User Content, we
                  store, process, and transmit User Content, along with related
                  information. This related information includes your profile
                  details, which enhance collaboration and sharing capabilities,
                  as well as data such as file size, upload time, collaborators,
                  and usage activity.
                </li>
              </ol>
            </li>
            <li className="counter">
              <b>What Personal Data do we collect and hold?</b>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  The types of Personal Data we may collect about an individual
                  will depend upon the nature of our interaction with them.
                  Paragraph 3.2 describes the two types of users we have.{' '}
                </li>
                <li className="counter">
                  Personal Data that we collect may include (but is not limited
                  to) the following:{' '}
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>
                      <p>
                        <strong>users with an account</strong>: your full name;
                        date of birth; contact details; business or company
                        details (if you sign up to our use your services on
                        behalf of your business); third party accounts you chose
                        to link to your Fileverse account; and the further
                        information set out in paragraphs 3, 3.5 and 6
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <strong>users without an account</strong>: we do not see
                        your User Content and only collect the information set
                        out in paragraph 3.5 and 6
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <strong>our investors and shareholders</strong>:
                        identification information and information about your
                        shareholding, to identify our investors and shareholders
                        and to administer their investment in Fileverse
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <strong>
                          our suppliers, potential suppliers, and their
                          representatives
                        </strong>
                        : generally information to assess your business (such as
                        its key personnel); and business contact information
                        (names, roles, contact details) to communicate with you,
                        arrange and administer your provision of services to us
                      </p>
                    </li>
                    <li>
                      <p>
                        {' '}
                        <strong>
                          our employees past and present, including job
                          applications
                        </strong>
                        : occupation and employment details including employment
                        status and any previous work experience; information in
                        connection with your employment with us (which may
                        include health information); and information from or in
                        connection with your resume or job application if you
                        apply for a position with us (including information from
                        referees and to verify your qualifications, work and
                        academic history), and
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>
                          any person who comes into contact with Fileverse or
                          our technologies
                        </strong>
                        : information about your contact with us; photographs
                        and/or images from camera footage such as CCTV cameras
                        in our premises; information from social media accounts
                        and profiles, and other publicly available sources; and
                        the information set out in paragraph 5.
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="counter">
              <b>How and when do we collect Personal Data?</b>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  We collect your Personal Data to allow us to conduct our
                  business functions, to provide and market our services and for
                  the specified purposes set out in paragraph 6. In some
                  circumstances the collection of Personal Data may be required
                  by law.{' '}
                </li>
                <li className="counter">
                  We may collect your Personal Data in the course of providing
                  you with goods or services, or:{' '}
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li> when you use services</li>
                    <li>
                      when you provide us, or you offer or apply to supply us,
                      with goods or services
                    </li>
                    <li>
                      when you provide information to us in any way (including
                      by completing a form, disclosing information over the
                      phone or via email, or providing us a business card)
                    </li>
                    <li>when you request information about us, our services</li>
                    <li>when you provide feedback to us</li>
                    <li>
                      when you visit or fill in a form on our Website (see
                      paragraph 5)
                    </li>
                    <li>
                      when you register for or use an account on our Website
                    </li>
                    <li>when you visit premises from which we operate</li>
                    <li>
                      when you (or your employer) provide that information to us
                      in the course of conducting or administering our
                      relationship with you, or when you are carrying out
                      activities in connection with our business operations
                    </li>
                    <li>when you submit a job application to us,</li>
                    <li>
                      when you otherwise contact us by telephone, fax, email,
                      social media, post or in person, or
                    </li>
                    <li>
                      where we are otherwise required or authorised by law to do
                      so.
                    </li>
                  </ol>
                </li>
                <li className="counter">
                  Generally, when providing our products and services, dealing
                  with our personnel, or obtaining goods and services from our
                  service providers, suppliers or contractors, we collect
                  personal information directly from the relevant individual
                  where reasonable and practicable.
                </li>
                <li className="counter">
                  We may also collect Personal Data about you from third parties
                  and other sources such as:
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>
                      third party accounts that you link to your Fileverse
                      account
                    </li>
                    <li>
                      your nominated representatives (eg spouse accountant,
                      power of attorney, brokers and other professional
                      advisors)
                    </li>
                    <li>publicly available sources of information, or</li>
                    <li>
                      related entities, companies and businesses of Fileverse,
                    </li>
                  </ol>
                  but we will only collect your Personal Data in this way if it
                  is unreasonable or impracticable to collect this information
                  directly from you or if we are otherwise permitted to do so.
                </li>
                <li className="counter">
                  If the Personal Data we collect includes sensitive
                  information, including health information, we will ask for
                  your consent to collect sensitive information, unless the law
                  allows us to collect it without your consent or this type of
                  information is uploaded by you as User Content.
                </li>
                <li className="counter">
                  Where we engage with you multiple times over a short period in
                  relation to the same matter, we may not provide you with a
                  separate notice about privacy each time we engage with you.
                </li>
                <li className="counter">
                  If you choose not to provide your Personal Data to us for the
                  purposes set out in this Privacy Policy, or if we do not or
                  are unable to collect the Personal Data we require, we may not
                  be able to provide you with requested information, products or
                  services, or to effectively conduct our relationship with you.
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>
                Information collected via our Website and applications
              </strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  Personal information may be collected by us and by our third
                  party service providers who assist us in operating our website
                  at{' '}
                  <a
                    href="https://fileverse.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    https://fileverse.io/
                  </a>
                  , including its subdomains and any other website we operate
                  from time to time (collectively the <strong>Website</strong>).
                </li>
                <li className="counter">
                  We may use various technological methods from time to time to
                  track the visiting patterns of individuals accessing our
                  Website or using our applications, including but not limited
                  to the methods set out in this paragraph 5.
                  <br />
                  <strong>
                    <i>Google Analytics</i>
                  </strong>
                </li>
                <li className="counter">
                  We use Google Analytics to help analyse how you use our
                  Website. Google Analytics generates statistical and other
                  information about website use by means of cookies, which are
                  stored on users&apos; computers. The information generated is
                  used to create reports about the use of our Website. Google
                  will store this information.
                </li>
                <li className="counter">
                  If you do not want your Website visit data reported by Google
                  Analytics, you can install the Google Analytics opt-out
                  browser add-on. For more details on installing and
                  uninstalling the add-on, please visit the Google Analytics
                  opt-out page at{' '}
                  <a
                    href="https://tools.google.com/dlpage/gaoptout"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    https://tools.google.com/dlpage/gaoptout
                  </a>
                  .
                  <br />
                  <strong>
                    <i>Click Stream Data</i>
                  </strong>
                </li>
                <li className="counter">
                  When you read, browse or download information from our
                  Website, we or our internet service provider may also collect
                  information such as the date, time and duration of a visit,
                  the pages accessed, the IP address of your computer, and any
                  information downloaded. This information is used for
                  statistical, reporting and website administration, maintenance
                  and improvement purposes.
                  <br />
                  <strong>
                    <i>Cookies</i>
                  </strong>
                </li>
                <li className="counter">
                  Our Website may use &apos;cookies&apos; from time to time.
                  Cookies are small text files that are transferred to a
                  user&apos;s computer hard drive by a website for the purpose
                  of storing information about a user&apos;s identity, browser
                  type or website visiting patterns. Cookies may be used on our
                  Website to monitor web traffic, for example the time of visit,
                  pages visited and some system information about the type of
                  computer being used. We use this information to enhance the
                  content and services offered on our Website.
                </li>
                <li className="counter">
                  Cookies are sometimes also used to collect information about
                  what pages you visit and the type of software you are using.
                  If you access our Website or click-through to our Website from
                  a link in an email we send you, a cookie may be downloaded
                  onto your computer&apos;s hard drive
                </li>
                <li className="counter">
                  Cookies may also be used for other purposes on our Website.
                </li>
                <li className="counter">
                  You can configure your browser to accept all cookies, reject
                  all cookies, or notify you when a cookie is sent. Each browser
                  is different, so check the &quot;Help&quot; menu of your
                  browser to learn how to change your cookie preferences.
                </li>
                <li className="counter">
                  If you disable the use of cookies on your web browser or
                  remove or reject specific cookies from our Website or linked
                  sites then you may not be able to gain access to all of the
                  content and facilities in those websites.
                  <br />
                  <strong>
                    <i>Web Beacons</i>
                  </strong>
                </li>
                <li className="counter">
                  Web beacons are images that originate from a third party site
                  to track visitor activities. We use web beacons to track the
                  visiting patterns of individuals accessing our Website.
                  <br />
                  <strong>
                    <i>Third party content </i>
                  </strong>
                </li>
                <li className="counter">
                  Some of the content on our Website may include applications
                  made available by third parties, such as social media buttons
                  or links that allow you to share content or links to our
                  Website through the relevant third party platforms. This also
                  includes where you chose to link a third party account to your
                  Fileverse account. These third party applications themselves
                  may facilitate collection of information by those third
                  parties, through your interaction with the applications and
                  sometimes even if you do not interact directly with them. We
                  are not responsible for the technical operation of these
                  applications or the collection and use practices of the
                  relevant third parties. Please visit the relevant third party
                  websites to understand their privacy practices and options
                  they may make available to you in relation to their collection
                  of your Personal Data.
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong> How do we use your Personal Data? </strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  We use the Personal Data we collect about you for our business
                  functions and activities, in order to operate our business
                  efficiently, and to market our services for the benefit of our
                  customers.
                </li>
                <li className="counter">
                  We may collect, hold and use your personal information:
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>to identify and communicate with you</li>
                    <li>
                      assist you get into your account, if you have lost access
                    </li>
                    <li>facilitate access to your User Content</li>
                    <li>
                      to enable us to provide you with requested information or
                      services
                    </li>
                    <li>
                      to otherwise assist you by providing information and
                      support
                    </li>
                    <li>
                      to help us to manage and enhance products or services we
                      provide to you
                    </li>
                    <li>
                      to help us to manage and enhance goods and services we
                      procure from our suppliers and subcontractors
                    </li>
                    <li>
                      to personalise and customise your experiences on our
                      Website
                    </li>
                    <li>
                      to manage and administer any account you may hold with us
                    </li>
                    <li>to promote and market our services to you</li>
                    <li>
                      to provide you with information that we believe may be of
                      interest to you or that you may be interested in
                      receiving, including advertising material, regarding us,
                      our clients, and our business partners
                    </li>
                    <li>
                      to conduct research for the purposes of improving existing
                      services or creating new services
                    </li>
                    <li>
                      to help us research the needs of our customers to enable
                      us to market our products and services with a better
                      understanding of your needs and the needs of customers
                      generally
                    </li>
                    <li>to protect you and us from fraud</li>
                    <li>
                      to provide for the safety and security of workers and
                      onsite visitors
                    </li>
                    <li>to help us manage our business operations</li>
                    <li>
                      for business support purposes including maintenance,
                      backup and audit
                    </li>
                    <li>to process any job application submitted by you</li>
                    <li>
                      to respond to any queries or complaints you may have, or
                    </li>
                    <li>to comply with our statutory and legal obligations.</li>
                  </ol>
                </li>
                <li className="counter">
                  As part of our services, you have to option to engage in
                  peer-to-peer (<strong>P2P</strong>) networks. Please be aware
                  that while using P2P networks, certain information may be
                  shared with other users in the network, including your IP
                  address. We do not collect or monitor the content transmitted
                  through P2P networks, but we advise you to be mindful of the
                  information you share, as it may be visible to other users and
                  outside of our direct control.
                </li>
                <li className="counter">
                  We may aggregate personal information for reporting,
                  statistical and analysis purposes, and for business, product
                  and service improvement purposes. This allows us to better
                  inform ourselves and anticipate our customers&apos;
                  preferences and requirements, and to monitor and improve the
                  effectiveness of our business, products and services. We may
                  also de-identify information for inclusion in such aggregated
                  databases or reports.
                </li>
                <li className="counter">
                  We reserve the right at all times to monitor, review, retain,
                  and/or disclose any information as necessary to satisfy any
                  applicable law, but we have no obligation to monitor the use
                  of the Website or to retain the content of any user session.
                </li>
                <li className="counter">
                  You consent to us using your Personal Data in the above ways
                  and as set out in this Privacy Policy.
                </li>
                <li className="counter">
                  We may otherwise collect, use or disclose your Personal Data
                  where the collection, use or disclosure is:
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>
                      in accordance with this Privacy Policy or any agreement
                      you enter into with us, or
                    </li>
                    <li>
                      required or authorised by law, including without
                      limitation the Singapore Personal Data Protection Act
                      2012.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>When do we disclose your Personal Data?</strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  Fileverse may disclose, or provide access to, your Personal
                  Data to third parties in connection with the purposes
                  described in paragraph 6.
                </li>
                <li className="counter">
                  Depending on the circumstances and the nature of your
                  engagement with us, we may disclose your Personal Data to our
                  related entities, to third parties that provide products and
                  services to us or through us, or to other third parties (such
                  as your referee(s) in connection with a job application you
                  have submitted).{' '}
                </li>
                <li className="counter">
                  Unless where authorised or required by law or to facilitate
                  our services to you, e.g. to our data storage providers,
                  Fileverse will not disclose your User Content to third
                  parties.
                </li>
                <li className="counter">
                  We may also disclose your Personal Data to:
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>
                      any of Fileverse&apos;s internal divisions, business units
                      or departments
                    </li>
                    <li>your nominated representatives</li>
                    <li>
                      other organisations or individuals who assist us in
                      providing services to you (including the third party
                      accounts you link to our services when we need to assist
                      you get into your account, if you have lost access)
                    </li>
                    <li>
                      professional service providers and advisors who perform
                      functions on our behalf, such as lawyers
                    </li>
                    <li>
                      medical providers including medical and rehabilitation
                      practitioners for assessing insurance claims
                    </li>
                    <li>
                      representatives, agents or contractors who are appointed
                      by us in the ordinary operation of our business to assist
                      us in providing services or administering our business
                      (such as for data storage or processing, printing,
                      mailing, marketing, planning and product or service
                      development)
                    </li>
                    <li>debt collecting agencies</li>
                    <li>
                      banks, lenders, valuers, insurers, brokers, auditors,
                      business consultants and IT service providers, and
                    </li>
                    <li>
                      Government, regulatory authorities and other organisations
                      as required or authorised by law (such as Centrelink or
                      the Police).
                    </li>
                  </ol>
                </li>
                <li className="counter">
                  We may also disclose your Personal Data to our Website host or
                  software application providers in certain limited
                  circumstances, for example when our Website experiences a
                  technical problem or to ensure that it operates in an
                  effective and secure manner.
                </li>
                <li className="counter">
                  As we continue to develop our business, we may buy, merge or
                  partner with other companies or organisations, and in so
                  doing, acquire customer Personal Data. In such transactions,
                  Personal Data may be among the transferred assets. Similarly,
                  in the event that a portion or substantially all of our
                  business or assets are sold or transferred to a third party,
                  we may also disclose certain information including your
                  Personal Data to a purchaser or potential purchaser in
                  connection with the sale or potential sale of us, our business
                  or any of our assets, including in insolvency.
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>Overseas disclosures</strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  Some of your Personal Data may be disclosed, transferred,
                  stored, processed or used overseas by us, or by third party
                  service providers. This may happen if:
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>our offices or related entities are overseas</li>
                    <li>we outsource certain activities overseas</li>
                    <li>
                      transactions, information, services or products have an
                      overseas connection, or
                    </li>
                    <li>
                      our computer systems including IT servers are located
                      overseas.
                    </li>
                  </ol>
                </li>
                <li className="counter">
                  You consent to the collection, use, storage, and processing of
                  your Personal Data in locations around the world – including
                  those outside your country.{' '}
                </li>
                <li className="counter">
                  In particular, your Personal Data may be disclosed to third
                  parties in Australia, Singapore, the EU and the US and such
                  other countries in which those parties or their, or our,
                  computer systems may be located from time to time, where it
                  may be used for the purposes described in this Privacy Policy.
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>Other uses and disclosures</strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  We may collect, use and disclose your Personal Data for other
                  purposes not listed in this Privacy Policy. If we do so, we
                  will make it known to you at the time we collect or use your
                  Personal Data.{' '}
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>Marketing</strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  You consent to us using your Personal Data for sending you
                  information, including promotional material, about us or our
                  products and services, as well as the products and services of
                  our related entities and third parties, now and in the future.
                  You also consent to us sending you such information by means
                  of direct mail, email, SMS and MMS messages.{' '}
                </li>
                <li className="counter">
                  If you do not want to receive marketing information from us,
                  you can unsubscribe in any of the following ways:
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>
                      clicking on the &apos;Unsubscribe&apos; or subscription
                      preferences link in a direct marketing email that you have
                      received from us
                    </li>
                    <li>
                      logging into your account on the Website and editing your
                      communication preferences, or
                    </li>
                    <li>
                      contacting us using the contact details specified in
                      paragraph 15.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>Storage and security of Personal Data held by us</strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  We aim to keep your Personal Data secure. Any Personal Data
                  that is collected via our Website or which is held on our
                  computer systems is protected by safeguards including
                  physical, technical (firewalls, SSL encryption etc) and
                  procedural methods.{' '}
                </li>
                <li className="counter">
                  If we find that we no longer require or have no further need
                  for your Personal Data we may de-identify it or remove it from
                  our systems and destroy all record of it.{' '}
                </li>
                <li className="counter">
                  We utilise data storage providers to store User Content, as
                  well as caching servers. Caching servers temporarily store
                  your User Content to reduce load times and ensure faster
                  access to frequently requested User Content. These servers do
                  not collect or store any Personal Data about you except for
                  the Personal Data included in your User Content. Any cached
                  data is typically deleted or refreshed after a set period to
                  ensure you are receiving the most up-to-date User Content.{' '}
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>
                You can access, update and delete your Personal Data
              </strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  You have control over your User Content and can access, amend
                  or delete it at any time via logging in to your account.{' '}
                </li>
                <li className="counter">
                  Outside of your User Content, your rights in relation to the
                  Personal Data that we hold to facilitate our services to you
                  include:
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>
                      you are generally entitled to access Personal Data that we
                      hold about you. If you request access to your Personal
                      Data, in ordinary circumstances we will give you full
                      access to your Personal Data. Depending on the nature of
                      the request, Fileverse may charge for providing access to
                      this information, however such charge will not be
                      excessive. However, there may be some legal or
                      administrative reasons to deny access. If we refuse your
                      request to access your Personal Data, we will provide you
                      with reasons for the refusal where we are required by law
                      to give those reasons.
                    </li>
                    <li>
                      we take all reasonable steps to ensure that any Personal
                      Data we collect and use is accurate, complete and
                      up-to-date. To assist us in this, you need to provide
                      true, accurate, current and complete information about
                      yourself as requested, and properly update the information
                      provided to us to keep it true, accurate, current and
                      complete.
                    </li>
                  </ol>
                  please contact us in any of the ways specified in paragraph 15
                  if you believe that the Personal Data is inaccurate,
                  incomplete or out of date, and we will use all reasonable
                  efforts to correct the information.
                  <br />
                  It would assist us to ensure we properly understand your
                  request, and allow us to respond more promptly, if requests
                  are made in writing and include as much detail as possible.
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>How do we deal with complaints about privacy?</strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  If you feel that we have not respected your privacy or that we
                  have conducted ourselves inconsistently with this Privacy
                  Policy, please contact us in any of the ways specified in
                  paragraph 15 and advise us as soon as possible. We will
                  investigate your queries and privacy complaints within a
                  reasonable period of time depending on the complexity of the
                  complaint.
                </li>
                <li className="counter">
                  It would assist us to respond to your complaint promptly if it
                  is made in writing. Please detail information relevant to your
                  complaint.
                </li>
                <li className="counter">
                  We will notify you of the outcome of our investigation.
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>Updates to this Privacy Policy</strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  We may, from time to time, review and update this Privacy
                  Policy, including to take into account new laws, regulations,
                  practices and technology. All Personal Data held by us will be
                  governed by our most recent Privacy Policy, posted on our
                  Website at:{' '}
                  <a
                    href="https://portal.fileverse.io/#/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline"
                  >
                    {' '}
                    https://portal.fileverse.io/#/privacy-policy
                  </a>{' '}
                  where the Privacy Policy will be located. Any changes to this
                  Privacy Policy may be advised to you by updating this page on
                  our Website. We will aim to provide reasonable advance notice
                  of such changes though this may not always be possible
                  depending on the circumstances. We encourage you to check this
                  page from time to time for any changes.
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong>
                What to do if you have a question, problem or complaint, or want
                to contact us about our use of your Personal Data or this
                Privacy Policy
              </strong>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  If you:
                  <ol className="alpha flex flex-col gap-2 ml-12">
                    <li>
                      have a query or concern about this Privacy Policy or our
                      Personal Data handling processes
                    </li>
                    <li>
                      wish to make a complaint in relation to a breach of your
                      privacy
                    </li>
                    <li>would like to access your Personal Data held by us</li>
                    <li>
                      would like to update or correct your Personal Data held by
                      us, or
                    </li>
                    <li>would like to opt out of direct marketing,</li>
                  </ol>
                  <span className="ml-4">
                    please contact us at{' '}
                    <a href="mailto:hello@fileverse.io" className="underline">
                      hello@fileverse.io
                    </a>
                    .
                  </span>
                </li>
              </ol>
            </li>
          </ol>
          This Privacy Policy was last updated on 27 November 2024.
        </div>
        <div
          id="appendix"
          className="flex flex-col gap-4 w-fit text-justify mt-12"
        >
          <ol className="counter flex flex-col gap-22">
            <li className="counter">
              <strong>
                Appendix 1 - Additional Information and Rights for Individuals
                Located in the EU or the UK
              </strong>
              <p>
                Under the GDPR individuals located in the EU or the UK have
                certain additional rights which apply to their Personal Data.
                Personal information under the GDPR is often referred to as
                personal data and is defined as information relating to an
                identified or identifiable natural person (individual). This
                Appendix sets out the additional rights we give to individuals
                located in the EU and the UK, including how we process Personal
                Data lawfully, transparently and fairly. Please read the Privacy
                Policy above and this Appendix carefully and contact us at the
                details at the end of the Privacy Policy if you have any
                questions.
              </p>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  <strong>What Personal Data is relevant?</strong>
                  <p>
                    This Appendix applies to the Personal Data set out in the
                    Privacy Policy above. This includes any sensitive
                    information also listed in the Privacy Policy above which is
                    known as ‘special categories of data’ under the GDPR.
                  </p>
                </li>
                <li className="counter">
                  <strong>Our commitment to you</strong>
                  <p>Your Personal Data will:</p>
                  <ul className="flex flex-col gap-2 list-disc ml-4">
                    <li>
                      be processed lawfully, fairly and in a transparent manner
                      by us;
                    </li>
                    <li>
                      only be collected for the specific purposes we have
                      identified in paragraph 7 and Personal Data will not be
                      further processed in a manner that is incompatible with
                      the purposes we have identified
                    </li>
                    <li>
                      be collected in a way that is adequate, relevant and
                      limited to what is necessary in relation to the purpose
                      for which the Personal Data is processed
                    </li>
                    <li>
                      be kept up to date, where it is possible and within our
                      control to do so (please let us know if you would like us
                      to correct any of your Personal Data)
                    </li>
                    <li>
                      be kept in a form which permits us to identify you, but
                      only for so long as necessary for the purposes for which
                      the Personal Data was collected, and
                    </li>
                    <li>
                      be processed securely and in a way that protects against
                      unauthorised or unlawful processing and against accidental
                      loss, destruction or damage.
                    </li>
                  </ul>
                </li>
                <li className="counter">
                  <strong>How we process Personal Data</strong>
                  <p>
                    We will only use your Personal Data when the law allows us
                    to. Most commonly, we will use your Personal Data in the
                    following circumstances:
                  </p>
                  <ul className="flex flex-col gap-2 list-disc ml-4">
                    <li>
                      where we need to perform the contract we are about to
                      enter into or have entered into with you
                    </li>
                    <li>
                      where it is necessary for our legitimate interests (or
                      those of a third party) and your interests and fundamental
                      rights do not override those interests, and
                    </li>
                    <li>where we need to comply with a legal obligation.</li>
                  </ul>
                  <p>
                    Generally, we do not rely on consent as a legal basis for
                    processing your Personal Data although we will get your
                    consent before sending third party direct marketing
                    communications to you via email or text message.{' '}
                  </p>
                  <p>
                    You have the right to withdraw consent to marketing at any
                    time by contacting us or by clicking on the unsubscribe link
                    in each marketing email you receive from us.
                  </p>
                  <p>
                    We will only use your Personal Data for the purposes for
                    which we collected it, unless we reasonably consider that we
                    need to use it for another reason and that reason is
                    compatible with the original purpose. If we need to use your
                    Personal Data for an unrelated purpose, we will notify you
                    and we will explain the legal basis which allows us to do
                    so.
                  </p>
                  <p>
                    If you refuse to provide us with certain Personal Data or if
                    you provide us with incomplete or inaccurate information
                    required for the performance of the contract or for
                    compliance with a legal obligation, please be aware that we
                    may not be able to provide or deliver to you all or parts of
                    the service that you require.
                  </p>
                  <p>
                    We only collect Special Categories of Personal Data where
                    you have given your explicit consent, it is necessary to
                    protect your vital interests or those of other people or
                    where you have deliberately made it public.
                  </p>
                  <p>
                    See above in the main section of this Privacy Policy at
                    paragraph 7 to find out more about the types of lawful basis
                    that we will rely on to process your Personal Data.
                  </p>
                </li>
                <li className="counter">
                  <strong>Data Transfers</strong>
                  <div className="flex flex-col gap-2">
                    <p>
                      We may transfer your personal data to third parties
                      located outside of the European Economic Area and the
                      United Kingdom. We will provide appropriate safeguards for
                      such transfers.{' '}
                    </p>
                    <p>
                      When transferring personal data out of the European
                      Economic Area or the United Kingdom, we will use either
                      the &quot;Standard Contractual Clauses&quot; as approved
                      by the European Commission or the United Kingdom
                      Government (as applicable) or any other contractual
                      agreement approved by the competent authorities as a basis
                      for the transfer.{' '}
                    </p>
                    <p>
                      Where these contractual agreements are not sufficient to
                      ensure an adequate level of data protection at the
                      recipient, we will implement supplemental safeguards.
                    </p>
                    <p>
                      Please contact us if you would like further information on
                      the specific mechanism used by us when transferring your
                      personal data out of the European Economic Area and/or the
                      United Kingdom.
                    </p>
                  </div>
                </li>
                <li className="counter">
                  <strong>Data Retention</strong>
                  <div className="flex flex-col gap-2">
                    <p>
                      You have control over your User Content and delete it at
                      any time.
                    </p>
                    <p>
                      Outside of your User Content, we will only retain your
                      Personal Data for as long as necessary to fulfil the
                      purposes we collected it for, including for the purposes
                      of satisfying any legal, accounting, or reporting
                      requirements.
                    </p>
                    <p>
                      To determine the appropriate retention period for Personal
                      Data, we consider the amount, nature, and sensitivity of
                      the Personal Data, the potential risk of harm from
                      unauthorised use or disclosure of your Personal Data, the
                      purposes for which we process your Personal Data, whether
                      we can achieve those purposes through other means and the
                      applicable legal requirements.
                    </p>
                    <p>
                      In some circumstances you can ask us to delete your data:
                      see ‘Your legal rights’ below for further information.
                    </p>
                    <p>
                      In some circumstances we may anonymise your Personal Data
                      (so that it can no longer be associated with you) for
                      analytics, research or statistical purposes in which case
                      we may use this anonymised information indefinitely
                      without further notice to you.
                    </p>
                  </div>
                </li>
                <li className="counter">
                  <strong>Your legal rights</strong>
                  <div className="flex flex-col gap-2">
                    <p>
                      You have control over your User Content and can access,
                      amend, delete your User Content at any time. Outside of
                      your User Content and under certain circumstances, you
                      have rights under data protection laws in relation to your
                      Personal Data:
                    </p>
                    <p>
                      You have the right to:
                      <ul className="flex flex-col gap-2 list-disc ml-4">
                        <li>
                          request access to your Personal Data (commonly known
                          as a &quot;data subject access request&quot;). This
                          enables you to receive a copy of the Personal Data we
                          hold about you and to check that we are lawfully
                          processing it
                        </li>
                        <li>
                          request correction of the Personal Data that we hold
                          about you. This enables you to have any incomplete or
                          inaccurate data we hold about you corrected, though we
                          may need to verify the accuracy of the new data you
                          provide to us
                        </li>
                        <li>
                          request erasure of your Personal Data. This enables
                          you to ask us to delete or remove Personal Data where
                          there is no good reason for us continuing to process
                          it. You also have the right to ask us to delete or
                          remove your Personal Data where you have successfully
                          exercised your right to object to processing (see
                          below), where we may have processed your information
                          unlawfully or where we are required to erase your
                          Personal Data to comply with local law. Note, however,
                          that we may not always be able to comply with your
                          request of erasure for specific legal reasons which
                          will be notified to you, if applicable, at the time of
                          your request
                        </li>
                        <li>
                          object to processing of your Personal Data where we
                          are relying on a legitimate interest (or those of a
                          third party) and there is something about your
                          particular situation which makes you want to object to
                          processing on this ground as you feel it impacts on
                          your fundamental rights and freedoms. You also have
                          the right to object where we are processing your
                          Personal Data for direct marketing purposes. In some
                          cases, we may demonstrate that we have compelling
                          legitimate grounds to process your information which
                          override your rights and freedoms
                        </li>
                        <li>
                          request restriction of processing of your Personal
                          Data. This enables you to ask us to suspend the
                          processing of your Personal Data in the following
                          scenarios:
                          <ul className="custom-list flex flex-col gap-2">
                            <li>
                              if you want us to establish the data&apos;s
                              accuracy
                            </li>
                            <li>
                              where our use of the data is unlawful but you do
                              not want us to erase it
                            </li>
                            <li>
                              where you need us to hold the data even if we no
                              longer require it as you need it to establish,
                              exercise or defend legal claims, and
                            </li>
                            <li>
                              you have objected to our use of your data but we
                              need to verify whether we have overriding
                              legitimate grounds to use it,
                            </li>
                          </ul>
                        </li>
                        <li>
                          request the transfer of your Personal Data to you or
                          to a third party. We will provide to you, or a third
                          party you have chosen, your Personal Data in a
                          structured, commonly used, machine-readable format.
                          Note that this right only applies to automated
                          information which you initially provided consent for
                          us to use or where we used the information to perform
                          a contract with you, and
                        </li>
                        <li>
                          withdraw consent at any time where we are relying on
                          consent to process your Personal Data. However, this
                          will not affect the lawfulness of any processing
                          carried out before you withdraw your consent. If you
                          withdraw your consent, we may not be able to provide
                          certain products or services to you. We will advise
                          you if this is the case at the time you withdraw your
                          consent.
                        </li>
                      </ul>
                    </p>
                    <p>
                      If you wish to exercise any of the rights set out above,
                      please contact us using the details at paragraph 16 in the
                      Privacy Policy.{' '}
                    </p>
                    <p>
                      You will not have to pay a fee to access your Personal
                      Data (or to exercise any of the other rights). However, we
                      may charge a reasonable fee if your request is clearly
                      unfounded, repetitive or excessive. Alternatively, we
                      could refuse to comply with your request in these
                      circumstances.
                    </p>
                    <p>
                      We may need to request specific information from you to
                      help us confirm your identity and ensure your right to
                      access your Personal Data (or to exercise any of your
                      other rights). This is a security measure to ensure that
                      Personal Data is not disclosed to any person who has no
                      right to receive it. We may also contact you to ask you
                      for further information in relation to your request to
                      speed up our response.
                    </p>
                    <p>
                      We try to respond to all legitimate requests within one
                      month. Occasionally it could take us longer than a month
                      if your request is particularly complex or you have made a
                      number of requests. In this case, we will notify you and
                      keep you updated.
                    </p>
                    <p>
                      You also have the right to lodge a complaint with the{' '}
                      <a
                        href="https://ico.org.uk/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        {' '}
                        Information Commissioner’s Office{' '}
                      </a>
                      (ICO). We would, however, appreciate the chance to deal
                      with your concerns before you approach the ICO, so please
                      contact us in the first instance.
                    </p>
                  </div>
                </li>
              </ol>
            </li>
            <li className="counter">
              <strong className="py-1">
                Appendix 2 - Additional Rights for Individuals Located in the US
              </strong>
              <p className="py-1">
                The California Consumer Privacy Rights Act provides California
                residents with specific rights regarding their Personal Data.
                This Appendix sets out additional rights granted to residents of
                California.{' '}
              </p>
              <ol className="counter flex flex-col gap-2">
                <li className="counter">
                  <strong> Sale or Share of Your Personal Data</strong>
                  <p>
                    We do not “sell” or “share” your Personal Data, as the term
                    is defined in the California Privacy Rights Act.
                  </p>
                </li>
                <li className="counter">
                  <strong> Data Retention</strong>
                  <div className="flex flex-col gap-2">
                    {' '}
                    <p>
                      You have control over your User Content and can delete it
                      at any time.
                    </p>
                    <p>
                      Outside of our User Content, we will only retain your
                      Personal Data for as long as necessary to fulfil the
                      purposes we collected it for, including for the purposes
                      of satisfying any legal, accounting, or reporting
                      requirements.
                    </p>
                    <p>
                      To determine the appropriate retention period for Personal
                      Data, we consider the amount, nature, and sensitivity of
                      the Personal Data, the potential risk of harm from
                      unauthorised use or disclosure of your Personal Data, the
                      purposes for which we process your Personal Data, whether
                      we can achieve those purposes through other means and the
                      applicable legal requirements.
                    </p>
                    <p>
                      In some circumstances you can ask us to delete your data:
                      see below in this Appendix for further information.
                    </p>
                    <p>
                      In some circumstances we may anonymise your Personal Data
                      (so that it can no longer be associated with you) for
                      analytics, research or statistical purposes in which case
                      we may use this anonymised information indefinitely
                      without further notice to you.
                    </p>
                    <strong>Your Right to Access, Delete, and Correct</strong>
                    <p>
                      You have control over your User Content and can access,
                      amend, delete your User Content at any time. Outside of
                      your User Content and under certain circumstances, you
                      have rights in relation to your Personal Data.
                    </p>
                    <p>
                      You have the right to request that we disclose certain
                      information to you about our collection and use of your
                      Personal Data over the past 12 months. Once we receive and
                      validate your request, we will include a list of your
                      Personal Data that may have been disclosed and the
                      categories of third parties the information may have been
                      disclosed to.{' '}
                    </p>
                    <p>
                      You may request that we delete any of your Personal Data
                      that we collected from you and retained, subject to
                      certain exceptions. Once we receive your request and
                      confirm your identity, we will review your request. We may
                      deny your deletion request if retaining the information is
                      proper and necessary or if an exception allowing us to
                      retain the information applies.{' '}
                    </p>
                    <p>
                      You may also request that we correct any of your Personal
                      Data that has become outdated or needs correction for any
                      reason.{' '}
                    </p>
                    <p>
                      If your request is approved, we will delete, correct, or
                      deidentify the relevant information and will direct our
                      service providers to take similar action.
                    </p>
                    <strong>
                      Exercising Your Rights to Know, Delete or Correct
                    </strong>
                    <p>
                      California residents may exercise their rights to know,
                      delete or correct as described above by using the contact
                      details at paragraph 16 in the Privacy Policy. Only you,
                      or someone legally authorized to act on your behalf, may
                      make a request to know or delete related to your Personal
                      Data.{' '}
                    </p>
                    <p>
                      You may only submit a request to know twice within a
                      12-month period. We endeavor to substantively respond to a
                      Verifiable Consumer Request within forty-five (45) days of
                      its receipt, unless we require an extension. If we
                      reasonably require an extension we will inform you of the
                      reason and extension period.
                    </p>
                    <p className="underline">Non-Discrimination</p>
                    <p>
                      We will not discriminate against you for exercising any of
                      your data subject rights.{' '}
                    </p>
                    <p>
                      We do not charge a fee to process or respond to your
                      Verifiable Consumer Request unless it is excessive,
                      repetitive, or manifestly unfounded. If we determine that
                      the request warrants a fee, we will tell you why we made
                      that decision and provide you with a cost estimate before
                      completing your request.
                    </p>
                    <p className="underline">
                      California’s Shine the Light Law
                    </p>
                    <p>
                      California Civil Code Section 1798.83 permits users of our
                      Services that are California residents to request certain
                      information regarding our disclosure of the information
                      you provide through the Services to third parties for
                      their direct marketing purposes. To make such a request,
                      by using the contact details at paragraph 16 in the
                      Privacy Policy.
                    </p>
                    <p>
                      Any such request must include “California Privacy Rights
                      Request” in the first line of the description and include
                      your name, street address, city, state, and ZIP code.
                      Please note that we are only required to respond to one
                      Shine the Light request per consumer each year, and we are
                      not required to respond to requests made by means other
                      than through the e-mail address or mailing address
                      provided in this Policy.
                    </p>
                    <p className="underline">
                      Participation in Financial Incentive Programs
                    </p>
                    <p>
                      We will notify you in the event we offer any financial
                      incentive for the use of your Personal Data. Please note
                      that participating in incentive programs is entirely
                      optional, you will have to affirmatively opt-in to the
                      program and you can opt-out of each program (i.e.,
                      terminate participation and forgo the ongoing incentives)
                      prospectively by following the instructions in the
                      applicable program description and terms.
                    </p>
                  </div>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}
