import React from 'react'
import { useMediaQuery } from '@mui/material'
import '../styles/acceptableUse.css'

export default function AcceptableUsePolicy() {
  const isMedia1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <div className={`w-full flex justify-center h-full py-10`}>
      <div
        className={`rounded-lg ${
          isMedia1025px ? 'w-full' : 'w-[50%]'
        }   color-border-default border shadow-lg overflow-auto styled-scrollbar p-8`}
      >
        <p>
          <b>Fileverse Acceptable Use Policy</b>
        </p>
        <div className="flex flex-col gap-4 w-fit text-justify">
          <ol className="flex flex-col gap-2 ml-6">
            <li>
              We created Fileverse (<strong>We, Us, Our</strong>) to give people
              a privacy-preserving and customisable platform. We&apos;re
              committed to making our platform safe, secure and welcoming. This
              Acceptable Use Policy (<strong>Policy</strong>) outlines some
              common-sense rules that all Users must adhere to during their time
              on Fileverse.
            </li>
            <li>
              This Policy forms part of Fileverse&apos;s Terms of Service,
              available at:{' '}
              <a
                href="https://portal.fileverse.io/#/terms-and-condition"
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                https://portal.fileverse.io/#/terms-and-condition
              </a>
              . All defined terms in this Policy take the meaning given to it in
              clause 1.1 of the Terms of Service.
            </li>
            <li>
              You are entirely responsible for Your Data, including content that
              you cause to be uploaded to the Services (collectively,{' '}
              <strong>Content</strong>). You are also responsible for any harm
              resulting from that Content. For your own protection and the
              protection of our community, you may not use{' '}
              <a
                href="https://www.fileverse.io"
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                www.fileverse.io
              </a>{' '}
              (<strong>Site</strong>) or the Services, or enable anyone else (
              <strong>You</strong>) to use the Site or the Services as follows:
              <ol className="flex flex-col gap-2 ml-6">
                <li>
                  You agree that You will not upload, post, or otherwise
                  distribute or facilitate the distribution of Your Data that:
                  <ol className="flex flex-col gap-2 ml-6">
                    <li>
                      is unlawful, threatening, abusive, harassing, defamatory,
                      libellous, deceptive, fraudulent, invasive of
                      another&apos;s privacy, contains explicit or graphic
                      descriptions or accounts of sexual acts, or otherwise
                      violates our rules or policies;
                    </li>
                    <li>
                      victimises, harasses, degrades, or intimidates an
                      individual or group of individuals on the basis of
                      religion, gender, sexual orientation, race, ethnicity,
                      age, or disability; or
                    </li>
                    <li>
                      infringes on any Intellectual Property Rights, or other
                      proprietary or other right of any party.
                    </li>
                  </ol>
                </li>
                <li>
                  You acknowledge that We:
                  <ol className="flex flex-col gap-2 ml-6">
                    <li>
                      have no responsibility or liability for the deletion or
                      failure to store Your Data uploaded by You or any other
                      user on the Services; and
                    </li>
                    <li>
                      are not responsible for Your Data uploaded to the Services
                      by You or any User nor are under any obligation to
                      monitor, move, remove, block, modify, edit, refuse to
                      upload or disable access to it.
                    </li>
                  </ol>
                </li>
                <li>
                  You will not:
                  <ol className="flex flex-col gap-2 ml-6">
                    <li>
                      probe, scan, or test the vulnerability of any system or
                      network;
                    </li>
                    <li>
                      breach or circumvent any security or authentication
                      protocols;
                    </li>
                    <li>
                      access, tamper with, or utilise non-public areas of the
                      Services or shared spaces that you haven&apos;t been
                      invited to;
                    </li>
                    <li>
                      disrupt or interfere with any User, host, or network, such
                      as by sending viruses, overloading, flooding, spamming any
                      part of the Services;
                    </li>
                    <li>
                      access, search, or create accounts for the Services using
                      methods other than our publicly supported interfaces;
                    </li>
                    <li>
                      send unsolicited communications, promotions,
                      advertisements, or spam;
                    </li>
                    <li>
                      {`provide altered, misleading, or false source-identifying information, including "spoofing" or "phishing";`}
                    </li>
                    <li>bypass storage space limits;</li>
                    <li>
                      sell the Services or purchase them from unauthorised
                      sellers;
                    </li>
                    <li>
                      publish, share, or store materials that constitute child
                      exploitation (including material that, while not illegal,
                      sexually exploits or promotes the sexual exploitation of
                      minors) or illegal pornography, or that are otherwise
                      indecent;
                    </li>
                    <li>
                      publish, share, or store content that promotes extreme
                      violence or terrorist activity, including propaganda
                      related to terrorism or violent extremism;
                    </li>
                    <li>
                      encourage bigotry, hatred, or violence against any
                      individual or group based on characteristics such as race,
                      religion, ethnicity, national origin, sex, gender
                      identity, sexual orientation, disability, or any other
                      traits associated with systemic discrimination;
                    </li>
                    <li>
                      harass or mistreat Fileverse personnel, representatives,
                      or agents acting on behalf of Fileverse;
                    </li>
                    <li>
                      violate the law in any form, including storing,
                      publishing, or sharing fraudulent, defamatory, or
                      misleading material, or violating the intellectual
                      property rights of others;
                    </li>
                    <li>
                      infringe upon the privacy or rights of others by
                      publishing, sharing, or storing confidential or Personal
                      Information without authorisation for harassment,
                      exposure, harm, or exploitation;
                    </li>
                    <li>
                      frame, embed, or otherwise create a mirror of any content
                      from our Site without our prior written consent; and
                    </li>
                    <li>
                      use the Services for backing up, or as infrastructure for,
                      your own cloud services.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              To use the Services, you must be at least 18 years old. If you are
              under 18, you may only access the Services with parental
              permission, and your parent or guardian must accept the Terms of
              Service on your behalf. Additionally, you must be at least 13
              years old to qualify for this option.
            </li>
            <li>
              We take the enforcement of this Policy very seriously. Should
              violations of the Policy come to our attention, we may take action
              as described in clause 5 below. If you ever feel unsafe on
              Fileverse, or believe that another User is abusing their
              privileges, you can report any relevant behaviour or content to{' '}
              <a
                href="mailto:hello@fileverse.io"
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                hello@fileverse.io
              </a>{' '}
            </li>
            <li>
              We reserve the right to take appropriate action in response to
              violations of this Policy, which may include removing or disabling
              storage space, restricting a User&apos;s access to the Services,
              or terminating an account.
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}
