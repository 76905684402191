import React from 'react'
import { useMediaQuery } from '@mui/material'
import '../styles/tos.css'

export default function TermsCondition() {
  const isMedia1025px = useMediaQuery('(max-width : 1025px)')
  return (
    <div className={`w-full flex justify-center h-full py-10`}>
      <div
        className={`rounded-lg ${
          isMedia1025px ? 'w-full' : 'w-[50%]'
        }   color-border-default border shadow-lg overflow-auto styled-scrollbar p-8`}
      >
        <div className="flex flex-col gap-2 text-justify">
          <strong>Fileverse Terms of Service</strong>
          <p>
            These Terms of Service (<strong>Terms</strong>) are between
            Fileverse (<strong>Fileverse, We, Us or Our </strong>) and you (
            <strong>You or Your</strong>) and Your Users. You agree to these
            Terms on behalf of You and Your Users, and these Terms shall bind
            You and Your Users.
          </p>
          <p>
            You acknowledge that these Terms are binding, and consent to these
            Terms by either clicking for acceptance or these Terms or
            registering to use or access the Services including sites or
            Fileverse.io mobile application (<strong>Sites</strong>).
          </p>
          <p>
            Our Acceptable Use Policy forms part of these Terms and is available
            at{' '}
            <a
              href="https://portal.fileverse.io/#/acceptable-use-policy"
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              https://portal.fileverse.io/#/acceptable-use-policy
            </a>
            . Our Privacy Policy provides Information about how we collect, use,
            disclose and otherwise handle your Personal Data, it also provides
            further Information as to how you can update, manage, export and
            delete your Information.
          </p>
          <ol className="flex flex-col gap-2 list-inside">
            <li>
              <strong>Definitions</strong>
              <p className="ml-6">
                In these Terms unless the context indicates a contrary
                intention:
              </p>
              <p className="ml-6">
                <strong>Affected Obligations</strong> has the meaning given to
                that term in the definition of Force Majeure Event.
              </p>
              <p className="ml-6">
                <strong>Affected Party</strong> has the meaning given to that
                term in the definition of Force Majeure Event.
              </p>
              <p className="ml-6">
                <strong>Business Day</strong> means a day excluding a Saturday,
                Sunday or public holiday in the Jurisdiction.
              </p>
              <p className="ml-6">
                <strong>Consumer Law</strong> means any applicable Law, statute,
                regulation, ordinance, code, standard or requirements of any
                government, governmental or semi-governmental body from time to
                time in force which relates to or affects consumer protection.
              </p>
              <p className="ml-6">
                <strong>Documentation</strong> means the user guides and other
                documents provided with the SaaS Services.
              </p>
              <p className="ml-6">
                <strong>Force Majeure Event</strong> means an event beyond the
                reasonable control of a party including any act, event or cause
                being:
              </p>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  an act of God, peril of the sea, accident of navigation, war,
                  sabotage, riot, act of terrorism, insurrection, civil
                  commotion, national emergency (whether in fact or Law),
                  martial law, fire, lightning, flood, cyclone, earthquake,
                  landslide, storm or other adverse weather conditions,
                  explosion, power shortage, epidemic, quarantine, radiation or
                  radioactive contamination;
                </li>
                <li>
                  an action or inaction of a Government Agency, including
                  expropriation, restraint, prohibition, intervention,
                  requisition, requirement, direction or embargo by legislation,
                  regulation, decree or other legally enforceable order; or
                </li>
                <li>
                  termination or withdrawal by Third Party providers of any of
                  the products, facilities or services used to provide the
                  Services, or other service disruptions involving hardware,
                  software of power systems within such party’s possession or
                  reasonable control and denial of service attacks, to the
                  extent that the act, event or cause directly results in a
                  party (<strong>Affected Party</strong>) being prevented from
                  or delayed in performing one or more of its material
                  obligations under these Terms (
                  <strong>Affected Obligations</strong>).
                </li>
              </ol>
              <p className="ml-6">
                <strong>Government Agency</strong> means any government or any
                public, statutory, governmental (including a local government),
                semi-governmental or judicial body, entity, department or
                authority and includes any self-regulatory organisation
                established under statute in the relevant jurisdiction.
              </p>
              <p className="ml-6">
                <strong>Information</strong> means any information, whether
                oral, graphic, electronic, written or in any other form,
                including:
              </p>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  forms, memoranda, letters, specifications, processes,
                  procedures, statements, formulae, technology, inventions,
                  trade secrets, research and development Information, know-how,
                  designs, plans, photographs, microfiche, business records,
                  notes, accounting procedures or financial Information, sales
                  and marketing Information, names and details of customers,
                  suppliers and agents, employee details, reports, drawings and
                  data;
                </li>
                <li>
                  copies and extracts made of or from that Information and data,
                  whether translated from the original form, recompiled,
                  partially copied, modified, updated or otherwise altered; and
                </li>
                <li>samples or specimens disclosed by either party.</li>
              </ol>
              <p className="ml-6">
                <strong>Insolvency Event</strong> means any one or more of the
                following events occurring in respect of a person:
              </p>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  a resolution is passed for the winding up of that person
                  (other than for the purposes of reconstruction or
                  amalgamation, which, in the case of a party, is on terms which
                  have been previously approved in writing by the other party);
                </li>
                <li>
                  a liquidator, provisional liquidator or receiver or receiver
                  and manager, voluntary administrator, or administrator of a
                  deed of company arrangement is appointed to all or any part of
                  the property of that person;
                </li>
                <li>
                  a receiver, receiver and manager, voluntary administrator or
                  an administrator of a deed of company arrangement, is
                  appointed to, or a mortgagee takes possession of, all or any
                  part of the business or assets of that person;
                </li>
                <li>
                  that person makes any composition or arrangement or assignment
                  with or for the benefit of its creditors;
                </li>
                <li>
                  that person or any creditor appoints a voluntary administrator
                  or a resolution is passed for that person to execute a deed of
                  company arrangement;
                </li>
                <li>
                  that person ceases, or threatens to cease to carry on its
                  business;
                </li>
                <li>
                  that person becomes unable to pay its debts as and when they
                  become due; or
                </li>
                <li>
                  any event analogous or equivalent to the events described in
                  paragraphs (a) to (g) occurs in respect of that person.
                </li>
              </ol>
              <p className="ml-6">
                <strong>Intellectual Property Rights</strong> means all present
                and future intellectual and industrial property rights conferred
                by statute, at common law or in equity and wherever existing,
                including:
              </p>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  Patents, inventions, designs, copyright, trade marks, brand
                  names, product names, domain names, database rights, rights in
                  circuit layouts, plant breeder&apos;s rights, know-how, trade
                  secrets and any other rights subsisting in the results of
                  intellectual effort in any field, whether or not registered or
                  capable of registration;
                </li>
                <li>
                  Any application or right to apply for registration of any of
                  these rights;
                </li>
                <li>
                  Any registration of any of those rights or any registration of
                  any application referred to in paragraph (b); and
                </li>
                <li>All renewals, divisions and extensions of these rights.</li>
              </ol>
              <p className="ml-6">
                <strong>Law</strong> means:
              </p>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  Principles of law or equity established by decisions of
                  courts;
                </li>
                <li>
                  Statutes, regulations or by-laws, of a Government Agency; and
                </li>
                <li>
                  Requirements and approvals (including conditions) of a
                  Government Agency that has the force of law, of any
                  jurisdiction of a country in which We operate or You reside in
                  or which is otherwise applicable to these Terms.
                </li>
              </ol>
              <p className="ml-6">
                <strong>Loss</strong> means any loss, damage, cost or expense.
              </p>
              <p className="ml-6">
                <strong>Our Materials</strong> means all of Our materials, such
                as software, application programming interface, design, text,
                editorial materials, Informational text, photographs,
                illustrations, audio clips, video clips, artwork and other
                graphic materials, and names, logos, trademarks and services
                marks (excluding Your Data), any and all related or underlying
                technology and any modifications, enhancements or derivative
                works of the foregoing.
              </p>
              <p className="ml-6">
                <strong>Personal Data</strong> means anything that is defined as
                personal data (or an equivalent term) or as sensitive
                Information in the relevant Privacy Laws.
              </p>
              <p className="ml-6">
                <strong>Privacy Law</strong> means any applicable Law, statute,
                regulation, ordinance, code, standard or requirements of any
                government, governmental or semi-governmental body of any
                jurisdiction of a country in which We operate or You reside in
                or which is otherwise applicable to these Terms, from time to
                time in force which relates to or affects privacy and data
                protection rights, health information or Personal Data.
              </p>
              <p className="ml-6">
                <strong>Related Entity</strong> has the meaning given to that
                term in the Corporations Act 2001 (Cth).
              </p>
              <p className="ml-6">
                <strong>Services</strong> means the sites and services including
                any software which are to be provided by Us to You.
              </p>
              <p className="ml-6">
                <strong>Third Party</strong> means a party other than Us or You.
              </p>
              <p className="ml-6">
                <strong>User</strong> means You and any individual You authorise
                to access and use the Services.
              </p>
              <p className="ml-6">
                <strong>Your Data</strong> means Your data or data supplied by
                or on behalf of You that is hosted on the Services.
              </p>
            </li>
            <li>
              {' '}
              <strong>Services</strong>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  Subject to these Terms, We will provide the Services to You.
                  We will use reasonable endeavours to perform the Services in
                  accordance with these Terms.
                </li>
                <li>
                  Without prejudice to any other right or remedy under these
                  Terms available to Us, Our ongoing provision of Services is
                  subject always to You complying with these Terms.
                </li>
                <li>
                  You will be responsible for all actions taken using your logon
                  and password.
                </li>
                <li>
                  You will be responsible for the content of all of Your Data
                  for complying with all applicable Laws in respect of Your
                  Data.
                </li>
                <li>
                  We may make any repairs, modifications, additions and upgrades
                  to the Services, including engaging in system maintenance, as
                  it deems necessary or desirable.
                </li>
                <li>
                  You agree that the Services are provided &quot;as-is&quot; and
                  that Your decision to enter into these Terms is not contingent
                  on the delivery of any future functionality or features.
                </li>
              </ol>
            </li>
            <li>
              <strong>Your obligations</strong>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  To use the Services, You must create an account with Us
                  (Account). In doing so, and through Your use of the Services,
                  You agree that You:
                  <ol className="flex flex-col gap-2 ml-6 list-inside">
                    <li>
                      will provide Us with complete and accurate registration
                      Information about you;
                    </li>
                    <li>
                      must not make the Services available to anyone other than
                      the Users;
                    </li>
                    <li>
                      must use commercially reasonable efforts to prevent
                      unauthorised access to or use of the Services, and notify
                      Us promptly of any such unauthorised access or use;
                    </li>
                    <li>
                      are responsible for the accuracy, quality and legality of
                      all of Your Data;
                    </li>
                    <li>
                      are responsible for all acts and omissions of Users as if
                      they were Your acts and omissions;
                    </li>
                    <li>
                      must take all reasonable steps to mitigate the risk
                      inherent in the use of the Services (such as loss of Your
                      Data) including performing regular backups of all of Your
                      Data and if necessary, having business continuity plans in
                      place in accordance with standard industry practice;
                    </li>
                    <li>
                      are responsible for providing the services and equipment
                      necessary to make use of the Services, which includes,
                      without limitation, internet connectivity;
                    </li>
                    <li>
                      must use the Services only in accordance with these Terms
                      and applicable Laws;
                    </li>
                    <li>
                      must not interfere with or disrupt the integrity or
                      performance of the Services;
                    </li>
                    <li>
                      undertake to promptly notify us in writing if you become
                      aware of any unauthorised access or use of your Account
                      and/or any breach of these Terms;
                    </li>
                    <li>
                      must not resell, rent, loan, sub-license, lease,
                      distribute or license the use of, or attempt to grant any
                      rights to, the Services to any Third Party, including but
                      not limited to using the Services to operate a service
                      bureau;
                    </li>
                    <li>
                      must not post, link to, or otherwise communicate or
                      distribute any misleading or deceptive, inappropriate,
                      profane, defamatory, infringing, obscene, indecent or
                      unlawful material or information, including any material
                      or information that We deem inappropriate, or otherwise
                      use the Services in a manner which is unlawful or would
                      infringe the rights of another person (including any
                      Intellectual Property Rights);
                    </li>
                    <li>
                      not use Your username and Password to breach security of
                      another User&apos;s Account, solicit information
                      (including login information) or access another
                      User&apos;s Account, or attempt to gain unauthorised
                      access to another network or server;
                    </li>
                    <li>
                      not interfere with anyone else&apos;s use and enjoyment of
                      the Services;
                    </li>
                    <li>
                      must not introduce to the Services, or use the Services
                      to, store or transmit a Malicious Code;
                    </li>
                    <li>
                      must not attempt to gain unauthorised access to the
                      Services or its related systems or networks; and
                    </li>
                    <li>
                      must comply with all reasonable directions provided by Us
                      with respect to the Services.
                    </li>
                  </ol>
                </li>
                <li>
                  You acknowledge that We may terminate the Services as set out
                  at clause 14 due to a failure by You or Your User to comply
                  with these Terms.
                </li>
                <li>
                  You acknowledge that You will be solely responsible and liable
                  for any losses, damages, liability and expenses incurred by Us
                  or a third party, due to any unauthorised usage of the Account
                  by either you or any other User or Third Party on your behalf.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <strong>Your Data</strong>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  When You use our Services, Your Data may involve your files,
                  content, messages, contacts, and other materials that you
                  chose to share with us. Your Data remains your property, and
                  these Terms do not grant Us any rights to it, except for the
                  limited rights necessary for Us to provide our Services.
                </li>
                <li>
                  We require Your permission to perform actions such as hosting,
                  backing up, and sharing Your Data upon your request. We
                  access, store, and scan Your Data. You authorise Us to do
                  this, and this permission extends to Our affiliates and
                  trusted Third Parties we collaborate with.
                </li>
                <li>
                  You agree to be solely responsible for Your Data. You warrant
                  and represent that Your Data will not violate these Terms.
                </li>
                <li>
                  You retain all intellectual property rights in Your Data, in
                  accordance with clause 9(f) of these Terms.
                </li>
                <li>
                  Where Your Data contains material from Third Parties, you
                  warrant that You have obtained the consents (including those
                  pursuant to any relevant copyright Laws) to do so.
                </li>
                <li>
                  You represent and warrant that:
                  <ol className="flex flex-col gap-2 ml-6 list-inside">
                    <li>
                      You own Your Data or have the necessary licences, rights,
                      consents and permissions to publish Your Data that you
                      upload to the Services;
                    </li>
                    <li>
                      Your Data will not infringe the Intellectual Property
                      Rights of any Third Party; and
                    </li>
                    <li>Your Data will not cause you to breach these Terms.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <strong>Your Account and Administration of your Account</strong>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  Our Services offer two options, Users can:
                  <ol className="flex flex-col gap-2 ml-6 list-inside">
                    <li>
                      create an Account with us to access the full range of
                      Services, or
                    </li>
                    <li>
                      use Our Services without an account, which will grant you
                      access to Our Services in a limited way.
                    </li>
                  </ol>
                </li>
                <li>
                  We use Privy.io to help facilitate Account creation. Privy.io
                  is a platform that provides tools for secure identity
                  management and account verification.
                </li>
                <li>
                  Upon creation of an Account via Privy.io, We will seek that
                  You connect Your crypto wallet or social media account to
                  streamline Account setup and for verification purposes. In the
                  event that You lose access to an Account or otherwise request
                  Information about an Account, We reserve the right to request
                  from You any verification We deem necessary before restoring
                  access to or providing Information about such Account.
                </li>
                <li>
                  You are solely liable and responsible for understanding the
                  settings, privileges and controls for the Services and for
                  controlling whom You permit to become a User, including
                  without limitation, the right for a User to invite other
                  Users. You are responsible for the activities of all of your
                  Users, including how such Users use Your Data. Further, You
                  acknowledge that any action taken by a User of Your Account,
                  is deemed by Us as an authorised action by You.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <strong>Passwords</strong>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  You may only access the Services by entering the password You
                  created during the Account registration process (which may
                  include access via a Third Party account as per clauses 5(b)
                  and 7) or which is updated by you from time to time (
                  <strong>Password</strong>).
                </li>
                <li>
                  You agree that You will not share, disclose, or permit
                  disclosure of Your Password, let anyone else access Your
                  Account or do anything that would risk the security of your
                  Account, and You must take steps to ensure that others do not
                  gain access to Your Password.
                </li>
                <li>
                  You are solely responsible for maintaining the security and
                  confidentiality of Your Password, and for any and all
                  statements made, and acts or omissions that occur through the
                  use of Your username and Password.
                </li>
                <li>
                  You may elect to change the Password at any time using the
                  facility provided on the Services or through the Third Party
                  account connected to your Account.
                </li>
                <li>
                  You must immediately notify Us of any Password which is lost,
                  inoperable or used in an unauthorised manner, or if You become
                  aware of an unauthorised access or use of Your Password to
                  access the Services.
                </li>
                <li>
                  We are not responsible for, and will not be liable for,
                  unauthorised access or use by a Third Party, damage,
                  destruction or misuse of Your Data, or for any loss, damage,
                  claims, costs or expenses arising out of the use or misuse of
                  Your Password.
                </li>
              </ol>
            </li>
            <li>
              {' '}
              <strong>Third Party Account Connection</strong>
              <ol className="flex flex-col gap-2 ml-6 list-inside">
                <li>
                  The Services allow You to connect with Third Party features
                  and integrations, this includes Privy.io. However, We do not
                  own or operate these third-party offerings. If You choose to
                  access or use any Third Party features or integrations, You do
                  so at your own risk. We are not responsible for any actions or
                  omissions by the Third Party, nor for the availability,
                  accuracy, or quality of their related content, products, or
                  services.
                </li>
                <li>
                  Notwithstanding clause 14, We reserve the right to terminate
                  Your access to the Services if any issues arise from Third
                  Party accounts connected to your Account. This includes, but
                  is not limited to, disruptions, malfunctions, or violations of
                  terms of service associated with such Third Party accounts. We
                  will provide you with notice of termination and an opportunity
                  to address any issues, if possible, before finalising the
                  termination.
                </li>
              </ol>
            </li>

            <li>
              <strong>Data Security</strong>
              <ol className="list-inside flex flex-col gap-2 ml-6">
                <li>As between the parties, You own all of Your Data.</li>
                <li>
                  You grant Us a non-exclusive, royalty-free, irrevocable,
                  worldwide licence to:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      use Your Data in an aggregated anonymised form for Our
                      internal business purposes, including to improve the
                      Services; and
                    </li>
                    <li>
                      share Your Data in an aggregated anonymized form with Our
                      Related Entities and other Third Parties We may contract
                      with from time to time.
                    </li>
                  </ol>
                </li>
                <li>
                  In providing the Services, We will:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      maintain reasonable administrative, physical and technical
                      safeguards for protection of the security, confidentiality
                      and integrity of Your Data; and
                    </li>
                    <li>
                      not access Your Data other than in accordance with these
                      Terms.
                    </li>
                  </ol>
                </li>
                <li>
                  We will use commercially reasonable endeavours when performing
                  the Services (including improving access to the Services) to
                  ensure that Your Data is not corrupted, deleted or subjected
                  to unauthorised use, and that no errors are introduced to Your
                  Data.
                </li>
                <li>
                  Your Data will be available via IPFS and stored on Filecoin
                  unless we terminate the Services in accordance with these
                  Terms.{' '}
                </li>
                <li>
                  At any time prior to, termination of your use of the Services,
                  You may request in writing, and We will provide, or allow You
                  to download, a copy of Your Data in an industry standard
                  format, within 21 days of the relevant request.
                </li>
              </ol>
            </li>
            <li>
              <strong>Intellectual Property</strong>
              <ol className="list-inside flex flex-col gap-2 ml-6">
                <li>
                  You acknowledge and agree that all Intellectual Property
                  Rights in the Services, inclusive of Our Materials are and
                  shall remain Our property, and nothing in these Terms should
                  be construed as transferring any aspects of such rights to You
                  or any Third Party.
                </li>
                <li>
                  We grant You and Your Users a non-exclusive, non-transferable,
                  royalty-free licence to use and access the Services, during a
                  Subscription Term, solely for Your internal purposes.{' '}
                </li>
                <li>You agree not to reverse engineer the Services. </li>
                <li>
                  You will not use or access the Services to, and will take
                  reasonable measures to ensure that Your Users do not use or
                  access the Services to:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>build a competitive product or service;</li>
                    <li>
                      make or have made a product or service using similar
                      ideas, features, functions or graphics of the Services;
                    </li>
                    <li>make derivative works based upon the Services;</li>
                    <li>
                      remove, modify or obscure any copyright, trade mark or
                      other proprietary rights that appear on the Services; or
                    </li>
                    <li>
                      &quot;frame&quot;, &quot;mirror&quot; or otherwise copy
                      any features, functions or graphics of the Services.
                    </li>
                  </ol>
                </li>
                <li>
                  You will immediately notify Us if You become aware that the
                  Services may infringe the Intellectual Property Rights of a
                  Third Party.{' '}
                </li>
                <li>
                  To the extent You provide any of Your Data in connection with
                  the Services, it is hereby agreed that We shall have no rights
                  in connection with Your Data, except as expressly permitted in
                  these Terms. You hereby grant Us a non-exclusive,
                  non-transferable, royalty free licence (including the right to
                  sub-license) to use, copy, edit, adapt and communicate Your
                  Data during the Subscription Term for the sole purpose of
                  providing the Services in accordance with these Terms and to
                  adapt that Data into an anonymised form and You perpetually
                  irrevocably licence Us to use, adapt, copy and communicate
                  that anonymised Data.{' '}
                </li>
              </ol>
            </li>
            <li>
              <strong>Privacy</strong>
              <ol className="list-inside flex flex-col gap-2 ml-6">
                <li>
                  You undertake to ensure that You and Your Users will comply
                  with the requirements of any Privacy Laws that may be
                  applicable when using the Services and otherwise in respect of
                  any Personal Datacollected, held, accessed, used or otherwise
                  dealt with by You in connection with these Terms, and that You
                  and they will not do or omit to do anything to cause Us to
                  breach such Privacy Laws
                </li>
                <li>
                  You acknowledge that:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      from time to time in order to perform Our obligations
                      under these Terms, We will collect Personal Data about You
                      and Your Users; and{' '}
                    </li>
                    <li>
                      in performing Our obligations under these Terms or as
                      otherwise required by Law, We may provide that Personal
                      Data to Third Parties, in the country in which You reside
                      or elsewhere.
                    </li>
                  </ol>
                </li>
                <li>
                  You undertake to take reasonable steps to ensure that You and
                  Your Users are aware:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      that We may from time to time collect Personal Data about
                      them in order to perform Our obligations under these
                      Terms; and{' '}
                    </li>
                    <li>
                      of Our privacy policy which is available at{' '}
                      <a
                        href="https://portal.fileverse.io/#/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        https://portal.fileverse.io/#/privacy-policy
                      </a>{' '}
                      (<strong>Privacy Policy</strong>).
                    </li>
                  </ol>
                </li>
                <li>
                  You must ensure that You have provided the appropriate
                  notifications and procured the necessary consents or
                  authorisations to allow Us to collect Your Data and the
                  Personal Data referred to in this clause 9 and to use Your
                  Data and such Personal Data as contemplated by these Terms and
                  Our Privacy Policy and in accordance with Privacy Laws.
                </li>
                <li>
                  You must notify Us immediately upon becoming aware of any
                  breach of any Privacy Laws that may be related to the
                  operation of the Services or the Personal Data.
                </li>
              </ol>
            </li>
            <li>
              <strong>Warranties</strong>
              <ol className="list-inside flex flex-col gap-2 ml-6">
                <li>
                  Each party warrants that it has full legal capacity and power
                  to enter into these Terms.
                </li>
                <li>
                  You warrant that You will comply with all applicable Laws and
                  you will not engage in any act or omission to cause Us to
                  breach any applicable Laws.
                </li>
                <li>
                  You warrant that You have not relied on any representation
                  made by Us which has not been stated expressly in these Terms
                  or upon any descriptions, illustrations or specifications
                  contained in any document including catalogues or publicity
                  material produced by Us.
                </li>
                <li>
                  Any representation, warranty, condition, guarantee, indemnity
                  or undertaking that would be implied in, or effect, these
                  Terms by legislation, common law, tort, equity, or by course
                  of performance, dealing, trade, custom or usage is excluded to
                  the maximum extent permitted by Law.
                </li>
                <li>
                  Nothing in these Terms excludes, restricts or modifies any
                  consumer guarantee, right or remedy conferred by the Consumer
                  Law or any other applicable Law that cannot be excluded,
                  restricted or modified by agreement.
                </li>
                <li>
                  To the fullest extent permitted by Law, Our liability for a
                  breach of a non-excludable guarantee referred to in this
                  clause 11(i) is limited, at Our option, to:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>the supplying of the Services again; or </li>
                    <li>
                      the payment of the cost of having the Services supplied
                      again.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Liability</strong>
              <ol className="list-inside flex flex-col gap-2 ml-6">
                <li>
                  In no event shall any party hereto and its affiliates,
                  subcontractors, agents and vendors (including Third Party
                  service providers), be liable under, or otherwise in
                  connection with these Terms for any indirect, exemplary,
                  special, consequential, incidental, or punitive damages; any
                  loss of, or damage to data, use, business, reputation, revenue
                  or goodwill; and/or the failure of security measures and
                  protections, whether in contractor, tort or otherwise.
                </li>
                <li>
                  To the extent permitted by Law, and subject to clause 12(c),
                  Our maximum aggregate liability to You or in connection with
                  these Terms or its subject matter, however arising (including
                  breach of contract, negligence, misrepresentation or other
                  claim), is limited to the Fees paid by You to Us for the
                  Services in the 12 months prior to the event giving rise to
                  the liability under these Terms.{' '}
                </li>
                <li>
                  Nothing in these Terms operates to limit or exclude:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      either party&apos;s liability to the other in respect of
                      direct Losses arising out of or in connection with:
                      <ol className="list-inside flex flex-col gap-2 ml-6">
                        <li>
                          damages for bodily injury (including death) and damage
                          to real property and tangible personal property; or
                        </li>
                        <li>
                          any fraudulent or unlawful acts or omissions; or
                        </li>
                        <li>the indemnities set out in clause 13.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Indemnity</strong>
              <ol className="list-inside flex flex-col gap-2 ml-6">
                <li>
                  We indemnify You against any and all claims by a Third Party
                  to the extent arising from a claim by a Third Party that Your
                  use of the Services in accordance with these Terms infringes
                  the Intellectual Property Rights of that Third Party.{' '}
                </li>
                <li>
                  You will indemnify, defend and hold Us harmless against all
                  claims, damages, obligations, liabilities, losses, reasonable
                  expenses or costs (Losses) incurred as a result of any Third
                  Party claim arising from
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      Your violation of these Terms or applicable Law; and/or{' '}
                    </li>
                    <li>
                      Your Data, including the use of Your Data by Us,
                      infringing or violating, any third party’s rights,
                      including, without limitation, intellectual property,
                      privacy and/or publicity rights.
                    </li>
                  </ol>
                </li>
                <li>
                  If a claim is made by a party alleging Your use of the
                  Services infringes that party&apos;s Intellectual Property
                  Rights, We will, at Our option either:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      modify the Services so that they become non-infringing; or
                    </li>
                    <li>
                      procure for You the right to continue using the Services.
                    </li>
                  </ol>
                </li>
                <li>
                  The indemnities in this are continuing obligations, separate
                  and independent from the other obligations of the parties, and
                  survive termination, completion or expiration of these Terms.
                </li>
                <li>
                  It is not necessary for a party to incur expense or to make
                  any payment before enforcing a right of indemnity conferred by
                  this clause.
                </li>
                <li>
                  You must pay on demand any amount You must pay under the
                  indemnities in this clause.
                </li>
              </ol>
            </li>
            <li>
              <strong>Termination</strong>
              <ol className="list-inside flex flex-col gap-2 ml-6">
                <li>
                  We reserve the right to terminate the Services, at our sole
                  discretion or to transfer operation of the Services to a third
                  party or smart contract. Prior to termination or transfer, we
                  will provide 30 daysWe reserve the right to terminate the
                  Services, at our sole discretion or to transfer operation of
                  the Services to a third party or smart contract. Prior to
                  termination or transfer, we will provide 30 days&apos; notice
                  to You to provide You with enough time to make arrangements if
                  any. notice to You to provide You with enough time to make
                  arrangements if any.{' '}
                </li>
                <li>
                  We may by notice in writing immediately terminate the Services
                  (in whole or in part) if You:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      become unable to pay amounts owed to Us under these Terms.
                    </li>
                    <li>fail to comply with the AUP.</li>
                  </ol>
                </li>
                <li>
                  Either party may terminate these Terms immediately upon
                  written notice to the other party if: the other party is in
                  material breach of these Terms and:
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>the breach is incapable of remedy; or</li>
                    <li>
                      where the other party fails to remedy the breach within 10
                      Business Days of receiving written notice of the breach
                      from the first party; or
                    </li>
                    <li>
                      the other party is the subject of an Insolvency Event.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <strong>Consequences of Termination</strong>
              <p className="ml-6">
                If we terminate the Services in accordance with these Terms, we
                will stop paying for storage for your Account. You will still be
                able to access Your Data but Your access to parts of the
                Services will be limited.{' '}
              </p>
            </li>
            <li>
              <strong>Force Majeure</strong>
              <p className="ml-6">
                Neither party to these Terms will be liable for a Force Majeure
                Event.
              </p>
            </li>
            <li id="general">
              <strong>General</strong>
              <ol className="list-inside flex flex-col gap-2 ml-6">
                <li>
                  <strong>Entire understanding</strong>
                  <p>
                    These Terms contains the entire understanding between the
                    parties concerning the subject matter of these Terms and
                    supersedes, terminates and replaces all prior agreements and
                    communications between the parties concerning that subject
                    matter.
                  </p>
                </li>
                <li>
                  <strong>No adverse construction</strong>
                  <p>
                    These Terms, and any provision of these Terms, is not to be
                    construed to the disadvantage of a party because that party
                    was responsible for its preparation.
                  </p>
                </li>
                <li>
                  <strong>No waiver</strong>
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      A failure, delay, relaxation or indulgence by a party in
                      exercising any power or right conferred on the party by
                      these Terms does not operate as a waiver of the power or
                      right.
                    </li>
                    <li>
                      A single or partial exercise of the power or right does
                      not preclude a further exercise of it or the exercise of
                      any other power or right under these Terms.
                    </li>
                    <li>
                      A waiver of a breach does not operate as a waiver of any
                      other breach.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Severability</strong>
                  <p className="ml-6">
                    Any provision of these Terms which is invalid in any
                    jurisdiction must, in relation to that jurisdiction:
                  </p>
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      A failure, delay, relaxation or indulgence by a party in
                      exercising any power or right conferred on the party by
                      these Terms does not operate as a waiver of the power or
                      right.
                    </li>
                    <li>
                      A single or partial exercise of the power or right does
                      not preclude a further exercise of it or the exercise of
                      any other power or right under these Terms.
                    </li>
                    <li>
                      A waiver of a breach does not operate as a waiver of any
                      other breach.
                    </li>
                    <p className="ml-6">
                      without invalidating or affecting the remaining provisions
                      of these Terms or the validity of that provision in any
                      other jurisdiction.
                    </p>
                  </ol>
                </li>
                <li>
                  <strong>Successors and assigns</strong>
                  <p className="ml-6">
                    These Terms binds and benefits the parties and their
                    respective successors and permitted assigns under clause
                    17(f)
                  </p>
                </li>
                <li>
                  <strong>No assignment</strong>
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      You cannot assign, novate or otherwise transfer the
                      benefit of these Terms without Our prior written consent.
                    </li>
                    <li>
                      We may assign, novate or otherwise transfer the whole or
                      part of these Terms without Your prior written consent.
                      You agree to execute any document necessary or desirable
                      to give effect to this clause 17(f).
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>No variation</strong>
                  <p className="ml-6">
                    These Terms cannot be amended or varied except in writing
                    signed by the parties.
                  </p>
                </li>
                <li>
                  <strong>Governing law and arbitration</strong>
                  <p className="ml-6">
                    These Terms are governed by and must be construed in
                    accordance with the laws in force Singapore in respect of
                    all matters arising out of or relating to these Terms, its
                    performance or subject matter.The application of the United
                    Nations Convention on Contracts for the International Sale
                    of Goods of April 11, 1980 shall be excluded.
                  </p>
                  <p className="ml-6">
                    The parties will use their best efforts to negotiate in good
                    faith and settle any dispute that may arise out of or relate
                    to this Agreement or any breach of it. If any such dispute
                    cannot be settled amicably through ordinary negotiations
                    between representatives of the parties, the dispute shall be
                    referred to the management of each party who will meet in
                    good faith in order to try and resolve the dispute. All
                    negotiations connected with the dispute will be conducted in
                    complete confidence and the parties undertake not to divulge
                    details of such negotiations except to their professional
                    advisers who will also be subject to such confidentiality
                    obligations and such negotiations shall be without prejudice
                    to the rights of the parties in any future proceedings. Any
                    dispute that is unable to be resolved after thirty (30) days
                    of the commencement of such negotiations shall be referred
                    to and finally resolved by arbitration in Singapore in
                    accordance with the Arbitration Rules of the Singapore
                    International Arbitration Centre (&quot;SIAC Rules&quot;)
                    for the time being in force, which rules are deemed to be
                    incorporated by reference in this clause. The parties agree
                    that any arbitration commenced pursuant to this clause shall
                    be conducted in accordance with the Expedited Procedure set
                    out in Rule 5.2 of the SIAC Rules. The seat of arbitration
                    shall be Singapore. The Tribunal shall consist of one
                    arbitrator. The language of the arbitration shall be
                    English.
                  </p>
                </li>
                <li>
                  <strong>Third Parties</strong>
                  <p className="ml-6">
                    A person who is not a party to this Agreement shall have no
                    rights under the Contracts (Rights Of Third Parties) Act,
                    Chapter 53B of Singapore, to enforce or enjoy the benefit of
                    any term of this Agreement.
                  </p>
                </li>
                <li>
                  <strong>Notices</strong>
                  <p className="ml-6">
                    Any notice or other communication to or by a party under
                    these Terms:
                    <ol className="list-inside flex flex-col gap-2 ml-6">
                      <li>may be given by personal service, post or email;</li>
                      <li>
                        must be in writing, legible and in English addressed
                        (depending on the manner in which it is given) as shown
                        in the Particulars, or to any other address last
                        notified by the party to the sender by notice given in
                        accordance with this clause;
                      </li>
                      <li>
                        is deemed to be given by the sender and received by the
                        addressee:
                        <ol className="list-inside flex flex-col gap-2 ml-6">
                          <li>
                            if delivered in person, when delivered to the
                            addressee;
                          </li>
                          <li>
                            if posted, at 9.00 am on the second Business Day
                            after the date of posting to the addressee whether
                            delivered or not; or
                          </li>
                          <li>
                            if sent by email transmission, and no undelivered
                            email message is received within 24 hours, at the
                            time and the date it was sent,
                          </li>
                        </ol>
                        but if the delivery or receipt is on a day which is not
                        a Business Day or is after 4.00 pm (addressee&apos;s
                        time), it is deemed to have been received at 9.00 am on
                        the next Business Day.
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <strong>Counterparts</strong>
                  <p className="ml-6">
                    If these Terms consist of a number of signed counterparts,
                    each is an original and all of the counterparts together
                    constitute the same document. A party may sign a counterpart
                    by executing a signature page and electronically
                    transmitting a copy of the signed page to each other party
                    or their authorised representative.
                  </p>
                </li>
                <li>
                  <strong>Operation of indemnities</strong>
                  <p className="ml-6">
                    Unless these Terms expressly provides otherwise:
                  </p>
                  <ol className="list-inside flex flex-col gap-2 ml-6">
                    <li>
                      each indemnity in these Terms survives the expiry or
                      termination of these Terms; and
                    </li>
                    <li>
                      a party may recover a payment under an indemnity in these
                      Terms before it makes the payment in respect of which the
                      indemnity is given.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Further assurances</strong>
                  <p className="ml-6">
                    A party, at its own expense and within a reasonable time of
                    being requested by another party to do so, must do all
                    things and execute all documents that are reasonably
                    necessary to give full effect to these Terms.
                  </p>
                </li>
                <li>
                  <strong>Relationship of parties</strong>
                  <p className="ml-6">
                    Unless these Terms expressly provides otherwise, nothing in
                    these Terms may be construed as creating a relationship of
                    partnership, of principal and agent or of trustee and
                    beneficiary.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}
